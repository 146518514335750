import { ICON_SVG } from '../icon.svg'
import { RouteRecordRaw } from 'vue-router'
import { InternalRootView, InternalSubView, component } from '../utils/route.utils'

export const ChannelName = 'order'
export const ChannelLabel = '订单'
export const OrderRoute: RouteRecordRaw = {
  name: ChannelName,
  path: `/${ChannelName}`,
  meta: {
    label: `${ChannelLabel}管理`,
    icon: 'Refrigerator',
    group: { name: ChannelName, label: ChannelLabel },
    showInLeftMenu: true,
    href: (d: any) => {
      return `/${ChannelName}`
    },
    svg: ICON_SVG.bank
  },
  children: [
    {
      name: `${ChannelName}-search`,
      path: `/${ChannelName}`,
      component: component(ChannelName, 'OrderList'),
      meta: {
        label: '查询',
        icon: 'Search',
        group: { name: ChannelName, label: ChannelLabel },
        showInLeftMenu: true,
        href: (d: any) => {
          return `/${ChannelName}`
        }
      }
    },
    {
      name: `${ChannelName}-audit`,
      path: `/${ChannelName}/:orderId(\\d+)/audit`,
      component: component(ChannelName, 'OrderAudit'),
      meta: {
        label: '审核订单',
        icon: 'Search',
        group: { name: ChannelName, label: ChannelLabel },
        showInLeftMenu: false,
        href: (d: any) => {
          return `/${ChannelName}`
        }
      }
    },
    {
      name: `${ChannelName}-new`,
      path: `/${ChannelName}/new`,
      component: component(ChannelName, 'OrderEdit'),
      meta: {
        label: '给客户报单',
        icon: 'Search',
        group: { name: ChannelName, label: ChannelLabel },
        showInLeftMenu: true,
        href: (d: any) => {
          return `/${ChannelName}`
        }
      }
    },
    {
      name: `${ChannelName}-edit`,
      path: `/${ChannelName}/:orderId(\\d+)`,
      component: component(ChannelName, 'OrderEdit'),
      meta: {
        label: '编辑订单',
        icon: 'Search',
        group: { name: ChannelName, label: ChannelLabel },
        showInLeftMenu: false,
        href: (d: any) => {
          return `/${ChannelName}`
        }
      }
    },
    {
      name: `${ChannelName}-view`,
      path: `/${ChannelName}/:orderId(\\d+)/view`,
      component: component(ChannelName, 'OrderDetail'),
      meta: {
        label: '订单详情',
        icon: 'Search',
        group: { name: ChannelName, label: ChannelLabel },
        showInLeftMenu: false,
        href: (d: any) => {
          return `/${ChannelName}`
        }
      }
    },
  ]
}

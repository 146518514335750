import { RouteRecordRaw } from 'vue-router'
import { ICON_SVG } from '../icon.svg'
import { InternalRootView, InternalSubView, component } from '../utils/route.utils'

export const ChannelName = 'withdraw'
export const ChannelLabel = '提现'
export const WithdrawRoute: RouteRecordRaw = {
  name: `${ChannelName}`,
  path: `/${ChannelName}`,
  meta: {
    label: `${ChannelLabel}管理`,
    icon: 'User',
    group: { name: ChannelName, label: ChannelLabel },
    href: () => {
      return `/${ChannelName}`
    },
    showInLeftMenu: true,
    svg: ICON_SVG.goods
  },
  children: [
    {
      name: `${ChannelName}-search`,
      path: `/${ChannelName}`,
      component: component(ChannelName, 'WithdrawTable'),
      meta: {
        label: '查询',
        icon: 'Search',
        group: { name: ChannelName, label: ChannelLabel },
        href: () => {
          return `/${ChannelName}`
        },
        showInLeftMenu: true
      }
    }
  ]
}

import { RouteRecordRaw } from 'vue-router'
import { ICON_SVG } from '../icon.svg'
import { InternalRootView, component } from '../utils/route.utils'

export const ChannelName = 'dfq/team'
export const ChannelLabel = '佣金设置'
export const CommissionRoute: RouteRecordRaw = {
  name: `${ChannelName}`,
  path: `/${ChannelName}`,
  meta: {
    label: `${ChannelLabel}`,
    icon: 'User',
    group: { name: ChannelName, label: ChannelLabel },
    href: () => {
      return `/${ChannelName}`
    },
    showInLeftMenu: true
  },
  children: [
    {
      name: `${ChannelName}-commission-goods-select`,
      path: `/${ChannelName}`,
      component: component(ChannelName, 'TeamSelectGoodsCommissionSettings'),
      meta: {
        label: '商品佣金设置',
        icon: 'Search',
        group: { name: ChannelName, label: ChannelLabel },
        href: () => {
          return `/${ChannelName}`
        },
        showInLeftMenu: true
      }
    }
  ]
}

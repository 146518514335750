import { RouteRecordRaw } from 'vue-router'
import { ICON_SVG } from '../icon.svg'
import { InternalRootView, component } from '../utils/route.utils'

export const ChannelName = 'team'
export const ChannelLabel = '团队管理'
export const TeamRoute: RouteRecordRaw = {
  name: `${ChannelName}`,
  path: `/${ChannelName}`,
  meta: {
    label: `${ChannelLabel}`,
    icon: 'User',
    group: { name: ChannelName, label: ChannelLabel },
    href: () => {
      return `/${ChannelName}`
    },
    showInLeftMenu: true,
    svg: ICON_SVG.goods
  },
  children: [
    {
      name: `${ChannelName}-search`,
      path: `/${ChannelName}`,
      component: component(ChannelName, 'TeamTable'),
      meta: {
        label: '团队长列表',
        icon: 'Search',
        group: { name: ChannelName, label: ChannelLabel },
        href: () => {
          return `/${ChannelName}`
        },
        showInLeftMenu: true,
        svg: ICON_SVG.goods
      }
    },
    {
      name: `${ChannelName}-new`,
      path: `/${ChannelName}/new`,
      component: component(ChannelName, 'TeamEdit'),
      meta: {
        label: '开通团队长',
        icon: 'Search',
        group: { name: ChannelName, label: ChannelLabel },
        href: () => {
          return `/${ChannelName}/new`
        },
        showInLeftMenu: true,
        svg: ICON_SVG.goods
      }
    },
    {
      name: `${ChannelName}-edit`,
      path: `/${ChannelName}/:agentId(\\d+)`,
      component: component(ChannelName, 'TeamEdit'),
      meta: {
        label: '新增',
        icon: 'Search',
        group: { name: ChannelName, label: ChannelLabel },
        href: () => {
          return `/${ChannelName}/new`
        },
        showInLeftMenu: false,
        svg: ICON_SVG.goods
      }
    },
    {
      name: `${ChannelName}-commission-goods`,
      path: `/${ChannelName}/commission/goods`,
      component: component(ChannelName, 'TeamGoodsCommissionSettings'),
      meta: {
        label: '佣金设置',
        icon: 'Search',
        group: { name: ChannelName, label: ChannelLabel },
        href: () => {
          return `/${ChannelName}`
        },
        showInLeftMenu: false,
        svg: ICON_SVG.goods
      }
    }, 
    {
      name: `${ChannelName}-commission-goods-agent`,
      path: `/${ChannelName}/:agentId(\\d+)/commission/goods`,
      component: component(ChannelName, 'TeamGoodsCommissionSettings'),
      meta: {
        label: '佣金设置',
        icon: 'Search',
        group: { name: ChannelName, label: ChannelLabel },
        href: () => {
          return `/${ChannelName}`
        },
        showInLeftMenu: false,
        svg: ICON_SVG.goods
      }
    },
  ]
}


import { LoginRoute } from './access/login.route'
import { RouteRecordRaw } from 'vue-router'
import { UserRoute } from './access/user.route'
import { HomeRoute } from './home.route'
import { AccessRoute } from './access/access.route'
import { AgentRoute } from './dfq/agent.route'
import { AgentOrderRoute } from './dfq/agent.order.route'
import { AgentUserRoute } from './dfq/agent.user.route'
import { GoodsRoute } from './dfq/goods.route'
import { ProductRoute } from './dfq/product.route'
import { OrderRoute } from './dfq/order.route'
import { WithdrawRoute } from './dfq/withdraw.route'
import { NoticeRoute } from './dfq/notice.route'
import { ShareTemplateRoute } from './dfq/share.template.route'
import { AdsBannerRoute } from './dfq/ads.banner.route'
import { ResourceRoute } from './dfq/shareBanner.route'
import { TeamRoute } from './dfq/team.route'
import { ExportRoute } from './dfq/export.route'
import { CommissionRoute } from './dfq/commission.route'
import { InternalRootView } from './utils/route.utils'
import { BankRoute } from './dfq/bank.route'
const system = import.meta.env.VITE_SYSTEM_TYPE

const adminRoutes = [HomeRoute, TeamRoute, AgentRoute, OrderRoute, WithdrawRoute,
  CommissionRoute, BankRoute, ProductRoute, GoodsRoute, ExportRoute, ShareTemplateRoute, AdsBannerRoute, ResourceRoute, NoticeRoute, UserRoute, AccessRoute]

adminRoutes.forEach(v => {
  v.component = InternalRootView
})

export const GlobalRoutes: RouteRecordRaw[] = [LoginRoute, ...adminRoutes]

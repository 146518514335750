import { RouteRecordRaw } from 'vue-router'
import { ICON_SVG } from '../icon.svg'
import { InternalSubView, InternalRootView, component } from '../utils/route.utils'

export const ChannelName = 'agent'
export const ChannelLabel = '会员'

export const AgentRoute: RouteRecordRaw = {
  name: `${ChannelName}`,
  path: `/${ChannelName}`,
  meta: {
    label: `${ChannelLabel}管理`,
    icon: 'User',
    group: { name: ChannelName, label: ChannelLabel },
    href: () => {
      return `/${ChannelName}`
    },
    showInLeftMenu: true,
    svg: ICON_SVG.goods
  },
  children: [
    {
      name: `${ChannelName}-search`,
      path: `/${ChannelName}/search`,
      component: component(ChannelName, 'AgentTable'),
      meta: {
        label: '查询',
        icon: 'Search',
        group: { name: ChannelName, label: ChannelLabel },
        href: () => {
          return `/${ChannelName}`
        },
        showInLeftMenu: true,
        svg: ICON_SVG.goods
      }
    },
    {
      name: `${ChannelName}-invite-member`,
      path: `/${ChannelName}/invite`,
      component: component(ChannelName, 'AgentInvite'),
      meta: {
        label: '邀请会员',
        icon: 'Search',
        group: { name: ChannelName, label: ChannelLabel },
        href: () => {
          return `/${ChannelName}/invite`
        },
        showInLeftMenu: import.meta.env.VITE_SYSTEM_TYPE === 'team' ? true : false,
        svg: ICON_SVG.goods
      }
    },
    {
      name: `${ChannelName}-commission-goods`,
      path: `/${ChannelName}/:agentId(\\d+)/commission/goods`,
      component: component(ChannelName, 'AgentGoodsCommissionSettings'),
      meta: {
        label: '佣金设置',
        icon: 'Search',
        group: { name: ChannelName, label: ChannelLabel },
        href: () => {
          return `/${ChannelName}`
        },
        showInLeftMenu: false,
        svg: ICON_SVG.goods
      }
    },
    {
      name: `${ChannelName}-edit`,
      path: `/${ChannelName}/:agentId(\\d+)`,
      component: component(ChannelName, 'AgentEdit'),
      meta: {
        label: '编辑',
        icon: 'Search',
        group: { name: ChannelName, label: ChannelLabel },
        href: () => {
          return `/${ChannelName}/new`
        },
        showInLeftMenu: false,
        svg: ICON_SVG.goods
      }
    },
    {
      name: `${ChannelName}-commission-goods-select`,
      path: `/${ChannelName}/commission/goods/select`,
      component: component(ChannelName, 'AgentSelectGoodsCommissionSettings'),
      meta: {
        label: '商品佣金设置',
        icon: 'Search',
        group: { name: ChannelName, label: ChannelLabel },
        href: () => {
          return `/${ChannelName}`
        },
        showInLeftMenu: false,
        svg: ICON_SVG.goods
      }
    }
  ]
}

if (import.meta.env.VITE_SYSTEM_TYPE == 'admin') {
}
